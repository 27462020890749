.horarios-container {
    padding: 20px;
  }
  
  .horarios-day {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 20px;
    background-color: #000000;
    color: #ffffff;
    border-radius: 10px;
  }
  
  .horarios-day label {
    flex: 1;
    margin-right: 10px;
  }
  
  .horarios-day select {
    flex: 0 1 100px;
    padding: 8px;
    margin: 0 5px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  /* Styles for the switch */
  .switch {
    position: relative;
    display: inline-block;
    max-width: 51px;
    max-height: 31px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #FF0087;
  }
  
  input:checked + .slider:before {
    transform: translateX(20px);
  }