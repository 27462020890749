/* InformacionPersonal.css */

.profile-edit-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background: #000000;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    color: #ffffff;
}

.cover-photo-container img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.profile-photo-container {
    display: flex;
    justify-content: center;
    margin-top: -50px;
}

.profile-photo-container img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.form-content {
    padding: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="url"],
.form-group input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #000000;
    color: white;
}

button {
    background-color: #FF0087;
    color: white !important;
    font-weight: bold !important;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
    display: block;
    width: 100%;
}

button:hover {
    background-color: #c8026c !important;
    color: white !important;
}

@media (max-width: 768px) {
    .profile-edit-container {
        padding: 10px;
    }
}