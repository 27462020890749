/* Reserva.css */
body {
  background-color: #282828;
}
.reservasContent {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ajuste de ancho */
  margin: initial;
  color: #fff;
}

.table-container {
  overflow-x: auto; /* Permite desplazamiento horizontal si la tabla excede el ancho de la pantalla */
  background-color: #282828;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  text-align: left;
  padding: 8px;
}

th {
  background-color: #282828;
  color: white; /* Asegúrate de que el texto sea legible sobre fondo oscuro */
}

tr:nth-child(even) {
  background-color: #282828; /* Alternar colores para mejor legibilidad */
}

.img-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 0 4px;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
}

button:hover:not(:disabled) {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  th,
  td {
    padding: 4px; /* Menor padding en dispositivos pequeños */
    font-size: 12px; /* Reducir el tamaño de la fuente */
  }

  .img-profile {
    width: 30px; /* Más pequeño para ahorrar espacio */
    height: 30px;
  }

  button {
    padding: 4px 8px; /* Botones más pequeños */
  }

  /* Esconde columnas menos importantes en dispositivos pequeños */
  .hide-on-mobile {
    display: none;
  }
}

.pagination-container {
  display: flex;
  justify-content: center !important; /* Centra el contenido horizontalmente */
  align-items: center !important; /* Centra el contenido verticalmente */
  padding: 2% 75% 0; /* Añade algo de padding para separar de otros elementos */
  width: 20% !important;
  flex-direction: row;
}

.pagination-container button {
  margin: 0 10px; /* Ajusta el espaciado entre botones */
  color: black !important;
}

button {
  margin: 0 10px; /* Ajusta el espaciado entre botones */
  color: black !important;
}

.pagination-container button:disabled {
  background-color: #fff;
}

.Proxima {
  background: rgba(255, 229, 0, 0.46);
  color: #fff;
  border-radius: 4px;
  border-style: solid;
  border-color: #ffe500;
  border-width: 1px;
  padding: 4px 12px 4px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 102px;
  position: relative;
}

.Cancelada {
  background: rgba(255, 0, 0, 0.58);
  color: #fff;
  border-radius: 4px;
  border-style: solid;
  border-color: #ff0000;
  border-width: 1px;
  padding: 4px 12px 4px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 102px;
  position: relative;
}

.Activa {
  background: rgba(101, 208, 114, 0.47);
  color: #fff;
  border-radius: 4px;
  border-style: solid;
  border-color: #65d072;
  border-width: 1px;
  padding: 4px 12px 4px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 102px;
  position: relative;
}

.Finalizada {
  background: rgba(255, 0, 135, 0.51);
  color: #fff;
  border-radius: 4px;
  border-style: solid;
  border-color: #ff0087;
  border-width: 1px;
  padding: 4px 12px 4px 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 102px;
  position: relative;
}

.indicator,
.indicator * {
  box-sizing: border-box;
}

.indicator {
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  position: relative;
}

.table-container table tr {
  transition: background-color 0.3s ease; /* Transición suave del color de fondo */
}

.table-container table tr:hover {
  background-color: #ff008824; /* Cambia el color de fondo al hacer hover */
  cursor: pointer; /* Cambia el cursor a tipo puntero para indicar interactividad */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translatey(20px);
  }
  to {
    opacity: 1;
    transform: translatey(0);
    color: #ff0087;
  }
}

@keyframes blink {
  50% {
    opacity: 0.5;
    color: #ff0087;
  }
}

.no-reservations-promote-with-us h2 {
  animation: fadeIn 2s ease-out, blink 1.5s ease-in-out infinite;
}
