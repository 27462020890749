/* Configuracion.css */

.configuracion-container {
  width: 97%;
  height: fit-content;
  margin: initial;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #000000;
  position: relative;
}

.configuracion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.switch-container {
  position: absolute;
  top: 20px; /* Ajustar según sea necesario */
  right: 20px; /* Ajustar según sea necesario */
  display: flex;
  align-items: center;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #FF0087;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.configuracion-container nav {
  border-bottom: 2px solid #ccc;
}

.configuracion-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}

.configuracion-container li {
  padding: 10px 55px;
}

.configuracion-container li a {
  text-decoration: none;
  color: #acacac;
  font-weight: bold;
  display: block;
  position: relative;
}

.configuracion-container li a:hover,
.configuracion-container li a:focus {
  color: #FF0087;
}

.configuracion-container li a.active {
  color: #FF0087;
}

.configuracion-container li a.active::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
  width: 100%;
  border-bottom: 4px solid #ffffff00;
}

.tab-content {
  padding: 20px;
}

.switchOpenClosedConfiguration {
  color: #ffff;
  font-weight: bold;
}

/* Pequeñas correcciones para mejorar la legibilidad en dispositivos móviles */
@media (max-width: 768px) {
  .configuracion-container nav ul {
    flex-direction: column;
  }

  .configuracion-container li {
    text-align: center;
  }

  .configuracion-container li a {
    padding: 10px;
  }
}