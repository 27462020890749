.login-page {
  height: 100vh;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.534),
      rgba(0, 0, 0, 0.593)
    ),
    url("../../assets/images/login2.jpg");
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  font-family: "SF Pro Text", Arial, sans-serif;
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  margin: 0 !important;
  padding: 0 !important;
}

.login-form-container {
  width: 100% !important;
  max-width: 360px !important; /* Limita el ancho máximo para grandes pantallas */
  padding: 20px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  background-color: #1e1e1e !important;
  border-radius: 10px !important;
}

.login-input-field,
.login-submit-button {
  width: 92% !important; /* Hace que los inputs tomen todo el ancho disponible */
  padding: 8px 16px;
  margin-bottom: 16px;
  border: none;
  border-radius: 12px;
}

.login-input-field {
  background-color: #282828;
  color: #f4f4f4;
  font-size: 16px;
}

.login-submit-button {
  background-color: #ff0087;
  color: white;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.loginSolAccesoLink {
  color: #ff0087 !important;
}

.login-top-bar {
  width: 100%;
  height: 49px;
  background-color: #121212;
  border-bottom: 0.5px solid rgba(111, 111, 112, 0.6);
}

@media (max-width: 600px) {
  .login-form-container {
    width: 90%; /* Ajusta el ancho en dispositivos pequeños */
  }

  .login-input-field,
  .login-submit-button {
    height: 44px; /* Altura ligeramente menor para dispositivos pequeños */
  }

  .login-input-field {
    font-size: 15px; /* Tamaño de fuente menor para mejor ajuste */
  }

  .login-submit-button {
    font-size: 16px;
  }
}

.login-welcome-back {
  text-align: center;
  color: white;
  font-size: 34px;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}

.login-access-request {
  text-align: center;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 16px;
}

.login-highlight {
  color: #ff0087;
  cursor: pointer;
}

/* Agregamos nuevas clases para manejar los textos dentro del formulario */
/* Estilos existentes continúan sin cambios */
.login-logo-image {
  margin-left: 8%;
  width: 310px;
  height: 72px;
}

.login-error-message {
  color: red;
}

.login-terms {
  width: 308px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8%;
}

.login-small-text {
  text-align: center;
  color: white;
  font-size: 11px;
  font-family: "SF Pro Text", Arial, sans-serif;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.07px;
}

.login-bold {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.login-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.75
  ); /* Slightly darker for better readability */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-alert-box {
  max-width: 90%; /* Reduce width to 90% of the screen */
  width: 555px; /* Maximum width */
  padding: 16px;
  background: #282828;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Slightly stronger shadow for better focus /
overflow: hidden; / Ensures no overflow of content */
}

.login-alert-content {
  position: relative;
  padding: 20px; /* Added padding for better spacing inside the alert */
}

.login-alert-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: white;
  font-size: 24px; /* Consider reducing for smaller screens */
}

.login-alert-logo {
  display: block;
  margin-bottom: 20px;
  width: 92px;
  height: 24px;
}

.login-alert-content p {
  color: white;
  font-size: 16px; /* Increase base size for better readability */
  font-family: ‘SF Pro Text’, Arial, sans-serif;
  text-align: center;
}

@media (max-width: 768px) {
  .login-alert-box {
    width: 80%; /* Slightly larger percentage for medium screens /
padding: 20px; / Increased padding for better visual spacing */
  }

  .login-alert-content p {
    font-size: 14px; /* Slightly smaller font size for medium screens */
  }

  .login-alert-close {
    font-size: 20px; /* Smaller close icon */
  }
}

@media (max-width: 480px) {
  .login-alert-box {
    width: 95%; /* Use more space for very small screens /
padding: 15px; / Adequate padding but less than on larger screens */
  }

  .login-alert-content p {
    font-size: 13px; /* Smaller text for small screens */
  }

  .login-alert-close {
    top: 5px;
    right: 5px;
    font-size: 18px; /* Even smaller close icon */
  }
}
