/* Estructura y Responsividad del Header */
.menuHeader {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
}

.menuHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

/* Selectores */
.addNewDropdown select {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  background-color: #ff0087;
  color: #fff;
  border: none;
  cursor: pointer;
}

/* Botón Colapsable */
.collapsible {
  background-color: #333;
  color: #fff;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  font-size: 1.1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
  margin-top: 5px;
  border-radius: 8px;
}

.collapsible:hover {
  background-color: #444;
}

.icon {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

.expand-icon,
.collapse-icon {
  margin-left: 10px;
  fill: currentColor;
  transition: transform 0.3s ease;
}

/* Contenido de la Sección Colapsable */
.content {
  padding: 15px;
  background-color: #1e1e1e;
  border-radius: 8px;
  border: 1px solid #ff0087;
  margin-top: 5px;
  max-width: 80%;
  margin-left: 5%;
}

.contenidoMenu {
  font-size: 1em;
  text-align: center;
  color: #fff;
  padding: 10px;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #555;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff0087;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Categorías */
.category-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-header h4 {
  margin: 0;
  font-size: 1.1em;
}

.menu-content {
  display: flex;
  flex-direction: column;
}

.menu-content p {
  margin: 3px 0;
}

.menu-item {
  display: grid;
  grid-template-columns: 60px 1fr auto auto; /* Imagen, Información, Eliminar, Switch */
  gap: 5px; /* Reducir espacio entre columnas */
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-top: 2%;
}

/* Columnas específicas */
.menu-photo-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-photo {
  width: 100%;
  max-width: 60px; /* Limitar el tamaño de la imagen */
  height: auto;
  border-radius: 8px;
}

.menu-info-column {
  display: flex;
  flex-direction: column;
  gap: 3px; /* Reducir espacio entre elementos de texto */
  color: #fff;
  font-size: 0.9em; /* Ajustar el tamaño de la fuente */
}

.menu-info-column h4 {
  margin: 0;
  font-size: 1em;
}

.price,
.discount-price {
  margin: 0; /* Eliminar márgenes extra */
  color: #ff0087;
}

/* Botón de eliminar */
.menu-delete-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-menu-button {
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.delete-menu-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Switch */
.menu-switch-column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #555;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff0087;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

/* Responsividad */
@media (max-width: 768px) {
  .menu-item {
    grid-template-columns: 50px 1fr auto auto; /* Ajuste para pantallas pequeñas */
  }

  .menu-photo {
    max-width: 50px;
  }

  .menu-info-column h4 {
    font-size: 0.9em;
  }
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 20px;
}

.modal-content {
  color: #fff;
  background: #2a2a2a;
  padding: 20px;
  border: 1px solid #ff0087;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  overflow-y: auto;
  max-height: 90vh;
}

/* Botones del Modal */
.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 10px;
}

.modal-buttons button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
}

.modal-buttons button:first-child {
  background-color: #ff0087;
  color: #fff;
  border: none;
}

.modal-buttons button:last-child {
  background-color: #3b3b3b;
  color: #fff;
  border: none;
}

/* Responsividad */
@media (max-width: 768px) {
  .menuHeader {
    font-size: 1.3em;
  }

  .collapsible {
    font-size: 1em;
    padding: 12px;
  }

  .menu-photo {
    width: 60px;
  }

  .modal-content {
    padding: 15px;
    max-width: 300px;
  }

  .menuHeaderContainer {
    flex-direction: column;
    gap: 10px;
  }
}

.menu-actions {
  display: grid;
  align-items: stretch;
  align-content: stretch;
  justify-items: center;
}
/* Botón de eliminar */
.delete-menu-button {
  background: none;
  border: none;
  color: #ff0000;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-menu-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
