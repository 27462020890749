body{
    background-color: var(--black, #121212) !important; /* Ajusta el fondo del contenido según sea necesario */
}
.contador,
.contador * {
    box-sizing: border-box;
}

.contador {
    background: var(--degradado-linear,
            linear-gradient(to left, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
            linear-gradient(168.31deg,
                rgba(255, 0, 135, 1) 0%,
                rgba(29, 18, 94, 1) 100%),
            linear-gradient(to left, #ffffff, #ffffff));
    border-radius: 12px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    position: relative;
    color: #ffffff;
    width: 52.5%;
    margin-left: 10% !important;
}

.frame-2165 {
    display: flex;
    flex-direction: column;
    gap: 1px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
}

.frame-2086 {
    padding: 4px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
}

.detail-section img {
    margin-top: 20px;
}

.detail-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 50% !important;
    margin-left: 10% !important;
}

.detail-section p {
    font-size: 16px;
    color: #333;
}

.detail-section h2 {
    color: #0056b3;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

table.greyGridTable {
    border: 2px solid #FFFFFF;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

table.greyGridTableHead,
table.greyGridTableUserInfo {
    width: 100%;
    border-collapse: collapse;
}

table.greyGridTableHead td,
table.greyGridTableHead th,
table.greyGridTableUserInfo td,
table.greyGridTableUserInfo th {
    padding: 10px;
    color: #000 !important;
}

table.greyGridTable td,
table.greyGridTable th {
    border: 1px solid #EEEEEE;
    padding: 10px;
    color: #000 !important;
}

table.greyGridTable tbody td {
    font-size: 14px;
}

table.greyGridTable tr:nth-child(even) {
    background: #FFFFFF !important;
}

table.greyGridTable td:nth-child(even) {
    background: #FFFFFF !important;
}

table.greyGridTable tfoot td {
    font-size: 14px;
}

@media (max-width: 768px) {
    table.greyGridTable {
        font-size: 12px;
    }

    table.greyGridTable td,
    table.greyGridTable th {
        padding: 8px;
    }
}

.tarjetaInfoDetalleReserva {
    border-radius: 12px;
    background-color: #FFFFFF;
}

.tbodyGridReserva {
    background: #FFFFFF !important;
}

.tdGridReserva {
    color: #FF0087 !important;
    font-size: bold !important;
}

.tbodyGreyGridTableHead {
    background: #FFFFFF !important;
}

/* Estilos para el overlay */
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Estilos para el modal */
.Modal {
    position: relative;
    margin: auto;
    background: black;
    border-radius: 4px;
    padding: 20px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Asegura que todos los elementos internos estén centrados */
}

/* Estilos específicos para los elementos internos del modal */
.Modal textarea {
    width: 100%;
    /* Ocupa todo el ancho disponible dentro del modal */
    padding: 10px;
    margin-bottom: 20px;
    /* Espacio entre la caja de texto y los botones */
    resize: none;
    /* Opcional: evita que el usuario cambie el tamaño */
    background-color: #282828;
    color: white;
}

.Modal button {
    margin: 5px;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #FF0087;
    color: white !important;
    font-weight: bold;
}

.Modal button:hover {
    margin: 5px;
    padding: 8px 16px;
    cursor: pointer;
    background-color: #ff0000;
    color: white;
}

#switchReserva {
    margin-right: 5% !important;
    display: flex !important;
}

#btnCancelar {
    max-width: 50% !important;
    min-width: 50% !important;
    background-color: #ff0000;
}

#btnFinalizar {
    max-width: 100% !important;
    min-width: 100% !important;
    background-color: #ff0000;
}

#btnActivar {
    max-width: 50% !important;
    min-width: 50% !important;
    background-color: #65D072;
}

#btnActivar :hover{
    max-width: 50% !important;
    min-width: 50% !important;
    background-color: #00ff1e !important;
}

#btnVolver {
    max-width: 50% !important;
    min-width: 50% !important;
    background-color: #00000000;
    font-size: 20px;
}

#btnVolver:hover {
    max-width: 50% !important;
    min-width: 50% !important;
    background-color: #00000000 !important;
    color: #FF0087 !important;
    font-size: 30px;
}

.btnVolverPieDetReservas {
    max-width: 20% !important;
    min-width: 20% !important;
    margin-left: 75% !important;
}

.colVolver {
    max-width: 20% !important;
    min-width: 20% !important;
}

.rowHeaderDetalleReserva {
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 57.5%;
    margin-left: 10%;
}

.detReservaNumberFollowers, .detReservaProfilePictureUrl, .detReservaNumberFollowed {
    text-align: center !important;
}

.detReservaQrImage {
    text-align: right !important;
}