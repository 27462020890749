.registrarEventoContainer {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1c1c1c;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #f4f4f4;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header h1 {
    color: white;
    margin: 0;
}

.closeButton {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    max-width: fit-content;
}

.closeButton button:hover{
    background-color: #e6007a;
}

.registrarEventoForm {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.registrarEventoForm label {
    display: flex;
    flex-direction: column;
    color: white;
}

.registrarEventoForm input,
.registrarEventoForm textarea,
.registrarEventoForm select {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #2c2c2c;
    color: white;
}

.registrarEventoForm button {
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #ff0087;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.registrarEventoForm button:hover {
    background-color: #e6007a;
}

/* Media Queries */
@media (max-width: 768px) {
    .registrarEventoContainer {
        padding: 15px;
    }

    .registrarEventoForm {
        gap: 10px;
    }

    .registrarEventoForm input,
    .registrarEventoForm textarea,
    .registrarEventoForm select {
        padding: 8px;
    }

    .registrarEventoForm button,
    .closeButton {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .registrarEventoContainer {
        padding: 10px;
    }

    .registrarEventoForm {
        gap: 8px;
    }

    .registrarEventoForm input,
    .registrarEventoForm textarea,
    .registrarEventoForm select {
        padding: 6px;
    }

    .registrarEventoForm button,
    .closeButton {
        font-size: 12px;
        padding: 6px;
    }
}

.galleryContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .imageWrapper {
    position: relative;
    width: calc(20% - 10px);
    box-sizing: border-box;
  }
  
  .eventImage {
    width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .coverCheckbox {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px;
    border-radius: 4px;
  }
  
  .removeImageButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .removeImageButton:hover {
    background: darkred;
  }
  .modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    margin: auto;
  }
  
  .overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .confirmButton {
    background: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .cancelButton {
    background: #f0ad4e;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }