.chat-chat-container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ajuste de ancho */
  margin: initial;
}

.chat-messages-container {
  max-height: 500px;
  overflow-y: auto;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
}

.chat-message-item {
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.chat-message-header {
  display: flex;
  align-items: center;
}

.chat-message-header img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-received {
  background-color: #ff0073a3;
  align-self: flex-start;
  width: 250px;
  align-items: start;
  margin-right: 75%;
}

.chat-sent {
  background-color: #00848b;
  align-self: flex-end;
  width: 250px;
  align-items: end;
  margin-left: auto;
}

.chat-send-message-form {
  display: flex;
  gap: 10px;
}

.chat-send-message-form input {
  flex-grow: 1;
  max-width: calc(100% - 48px); /* Considerando el botón de enviar y el de emojis */
}

.chat-send-message-form button {
  padding: 10px 20px;
}

.chat-fechaMensaje {
  font-size: small;
}

.chat-emoji-picker-react {
  position: absolute;
  bottom: 50px; /* Ajustar según necesidad */
  right: 10px; /* Ajustar según necesidad */
  z-index: 2;
}

.chat-send-message-form {
  display: inline-flex !important;
  height: 100px !important;
}

.btnChatEmojiPicker {
  width: 10% !important;
}

.btnChatEnviar {
  width: 15% !important;
  background-color: #ff0073a3 !important;
}

.emoji-picker-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.emoji-picker-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 50;
}

.headerH2Chat {
  color: #ffff !important;
}