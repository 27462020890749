.dashboard-container {
  display: flex !important;
  height: 100vh !important; /* Asegura que el contenedor del dashboard tenga toda la altura de la ventana */
  width: 100vw !important; /* Ocupa todo el ancho de la ventana */
  overflow: hidden !important; /* Evita desbordamientos innecesarios dentro del dashboard */
  color: #fff !important;
}

.sidebar {
width: 250px;  /* Ancho fijo para el sidebar */
height: 100%;  /* Ocupa todo el alto disponible */
background-color: #333; /* Color de fondo para diferenciar el sidebar */
}

.main-content {
  flex-grow: 1 !important; /* Toma el espacio restante */
  display: flex !important;
  flex-direction: column !important;
  overflow-y: auto !important; /* Permite desplazamiento vertical si es necesario */
}

.content {
  flex-grow: 1 !important; /* Asegura que el contenido toma todo el espacio disponible */
  padding: 20px !important;
  background-color: var(--black, #121212) !important; /* Color de fondo */
  color: #fff !important; /* Color del texto */
}

@media (max-width: 768px) {
  .sidebar {
    width: 100px; /* Más pequeño para dispositivos móviles */
  }

  .main-content, .dashboard-container {
    flex-direction: column; /* Cambia la orientación para mejor visualización en móviles */
  }
}