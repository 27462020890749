.detalleEventoContainer {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #1c1c1c;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #f4f4f4;
  }
  
  .backButton {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #ff0087;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .eventTitle {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .eventDescription {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .eventDetails {
    font-size: 16px;
    margin-bottom: 8px;
  }
  
  .talentContainer, .imagesContainer {
    margin-top: 20px;
  }
  
  .talentContainer h2, .imagesContainer h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .imagesContainer img {
    width: 100%;
    max-width: 300px;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
  }