.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #222;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 350px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-content h4 {
  margin-top: 0;
  text-align: center;
  font-size: 1.5em;
  color: #ff0087;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: stretch;
  justify-content: center;
}

label {
  font-size: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #ccc;
  text-align: left; /* Alineación a la izquierda */
}

input[type="text"],
input[type="number"],
textarea,
select {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #555;
  border-radius: 5px;
  background: #333;
  color: #fff;
  width: 100%;
}

input[type="file"] {
  margin-top: 10px;
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  border-color: #ff0087;
  outline: none;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}

.modal-buttons button {
  flex: 1;
  padding: 10px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.modal-buttons button[type="submit"] {
  background: #ff0087;
  color: #fff;
}

.modal-buttons button[type="submit"]:hover {
  background: #e60074;
}

.modal-buttons button[type="button"] {
  background: #444;
  color: #fff;
}

.modal-buttons button[type="button"]:hover {
  background: #555;
}

.modal-buttons button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
