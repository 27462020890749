.post-item-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 10px;
}

.post-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post-item-header-name {

}

.post-item-body {

}

.post-item-footer {

}
