/* src/components/Header.css */

.header-prinder,
.header-prinder * {
    box-sizing: border-box;
}

.header-prinder {
    background: var(--black, #121212);
    border-style: solid;
    border-color: var(--white, #ffffff);
    border-width: 0px 0px 0.5px 0px;
    padding: 0px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; /* Esto asegura que los elementos estén distribuidos */
    position: sticky;
    width: 100%;
    top: 0;
}

.logo {
    flex-shrink: 0;
    height: 41px;
    position: relative;
}

.publicitario-blanco-1 {
    width: 120px;
    height: 41px;
    object-fit: cover;
}

.acciones {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    justify-content: flex-end; /* Alineación a la derecha */
    flex-shrink: 0;
}

.icon-qr, .icon-notification {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
}

#notification-count {
    color: #ffffff !important;
    font-weight: bold !important;
}

.group, .group-1592 {
    width: 24px;
    height: 24px;
}

.group-1592-Nuevas {
    width: 50px;
    height: 50px;
}

.qr {
    width: 35px;
    height: 35px;
    margin-top: 50% !important;
}

.logout-group-1592 {
    width: 35px;
    height: 35px;
}

/* Estilos para el selector de idioma */
.language-selector {
    margin: 0 10px;
}

.language-selector select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    color: #000;
}

/* Estilos generales para el modal de notificaciones */
.header-notification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
}

.header-notification-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header-notification-header {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
    font-weight: bold;
}

.header-notification-list {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    max-height: 300px;
}

.header-notification-list li {
    border-bottom: 1px solid #eaeaea;
    padding: 10px;
    cursor: pointer;
}

.header-notification-list li:hover {
    background-color: #f5f5f5;
}

.header-notification-details {
    padding: 15px;
    text-align: justify;
}

.header-notification-close-btn {
    display: block;
    padding: 8px 16px;
    margin: 20px auto 0;
    font-size: 16px;
    color: #fff;
    background-color: #FF0087;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.header-notification-close-btn:hover {
    background-color: #860047;
}

.header-notification-empty {
    padding: 20px;
    text-align: center;
    color: #666;
    font-size: 16px;
}

.animacionSinPrinderNotificaciones {
    width: 150px;
    height: 100px;
}

.animacionLoading {
    width: 150px;
    height: 150px;
}

.loadingDIV {
    justify-content: center;
    align-items: center;
    padding: auto;
    margin-left: 20%;
    margin-top: 10%;
}

.language-selector {
    position: relative;
  }
  
  .lenguajeSelectorHeader {
    display: flex;
    align-items: center;
  }
  
  .lenguajeSelectorHeader option {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  
  .option-content {
    display: flex;
    align-items: center;
  }
  
  .flag-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }