.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  padding: 20px; /* Añade un margen interno para dispositivos pequeños */
}

.modal-content {
  background: #2c2f33;
  color: #fff;
  padding: 25px;
  border-radius: 12px;
  width: 100%;
  max-width: 480px;
  max-height: 80vh;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  font-family: Arial, sans-serif;
}

.modal-content h4 {
  margin-top: 0;
  text-align: center;
  font-size: 1.7em;
  color: #ff4da6;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

label {
  font-size: 1em;
  color: #e1e1e1;
  margin-bottom: 5px;
}

input[type="text"],
input[type="number"],
select,
textarea {
  padding: 12px;
  border: 1px solid #444;
  border-radius: 8px;
  background: #40444b;
  color: #fff;
  width: 95%;
  font-size: 1em;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus,
select:focus {
  border-color: #ff4da6;
  outline: none;
}

input[type="file"] {
  margin-top: 10px;
}

.preview-image {
  display: block;
  max-width: 100%;
  max-height: 200px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.image-preview-container {
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

.delete-image-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100%;
  margin-top: 20px;
}

.modal-buttons button {
  flex: 1;
  padding: 12px;
  font-size: 1em;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.modal-buttons button[type="submit"] {
  background: #ff4da6;
  color: #fff;
}

.modal-buttons button[type="submit"]:hover {
  background: #e60074;
}

.modal-buttons button[type="button"] {
  background: #555;
  color: #fff;
}

.modal-buttons button[type="button"]:hover {
  background: #666;
}

.modal-buttons button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.category-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.category-tag {
  background-color: #40444b;
  color: #fff;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category-tag:hover {
  background-color: #555;
}

.category-tag.selected {
  background-color: #ff4da6;
  color: #fff;
}
