.sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--black, #121212);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  border-right: 1px solid var(--white, #ffffff);
  overflow-y: auto;
  transition: width 0.3s;
}

.sidebar.open {
  width: 250px;
}

.sidebar nav {
  width: 100%;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  width: 100%;
}

.sidebar li a,
.sidebar li span {
  display: flex;
  padding: 4px 16px;
  color: white;
  text-decoration: none;
  cursor: pointer;
  align-items: center;
}

.sidebar li a:hover,
.sidebar li span:hover {
  background-color: #ff0087;
  border-radius: 5px;
}

.sidebar .icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.sidebar-item-separator {
  padding: 4px 16px;
  background-color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar {
    width: 60px;
    padding-top: 10px;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar li a,
  .sidebar li span {
    justify-content: center;
    padding: 10px 0;
  }

  .sidebar .icon {
    margin: 0;
  }

  .sidebar li a span {
    display: none;
  }

  .sidebar.open li a span {
    display: inline;
    margin-left: 10px;
  }
}
