.registro-usuario-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #ffffff;
    max-width: 80%;
    margin: 0 auto;
}

.registro-usuario-form-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    background-color: #333;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 1000px;
}

.registro-usuario-form-group {
    display: flex;
    flex-direction: column;
}

.registro-usuario-buttons {
    grid-column: span 2;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.registro-usuario-button,
.registro-usuario-button-back {
    padding: 10px 20px;
    background-color: #FF0087;
    color: white;
    border: none;
    cursor: pointer;
    max-width: 150px;
}

.registro-usuario-button-back {
    background-color: #f44336;
}

.registro-usuario-button:hover,
.registro-usuario-button-back:hover {
    opacity: 0.8;
}

.avatarUsuarioEmployee {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.input-error {
    box-shadow: 0 0 5px 2px red !important;
    border-color: red !important;
}

.role-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
}

.role-item span {
    flex: 1;
}

.role-item button {
    margin-left: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.role-item button:hover {
    background-color: darkred;
}

.select-role {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
}

/* Estilos para el modal minimalista */
.minimalist-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.minimalist-modal h2 {
    margin-top: 0;
    font-size: 20px;
    color: #333;
}

.minimalist-modal ul {
    list-style: none;
    padding: 0;
    margin: 15px 0;
}

.minimalist-modal ul li {
    margin-bottom: 10px;
}

.minimalist-modal ul li button {
    background-color: #FF0087;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
}

.minimalist-modal ul li button:hover {
    background-color: #FF0087;
}

.minimalist-modal button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
}

.minimalist-modal button:hover {
    background-color: #5a6268;
}

.btnModalRolesCerrar {
    background-color: #5a6268 !important;
}

.btnModalRolesCerrar:hover {
    background-color: #3f4143 !important;
}

/* Fondo de la superposición del modal minimalista */
.minimalist-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
}

.btnEliminarRol {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.btnEliminarRol:hover {
    background-color: #c82333;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 77%;
    padding: 10px;
}

.header-title {
    margin: 0;
    font-size: 24px;
    flex-grow: 1;
}

.closeButtonActualizar {
    width: 30px;
    background: none !important;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #000;
}

.closeButtonActualizar:hover {
    width: 30px;
    background: none !important;
    color: #c82333 !important;
}