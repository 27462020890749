.users-table {
    width: 100%;
    border-collapse: collapse;
    color: #ffff;
    border: 1px;
    border-radius: 10px;
}

.users-table th, .users-table td {
    border: 1px solid #000000;
    padding: 8px;
}

.users-table tr:nth-child(even) {
    background-color: #000000;
}

.users-table tr:hover {
    background-color: #FF0087;
}

.users-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #FF0087;
    color: white;
}

.add-user-button {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #FF0087;
    color: white;
    border: none;
    cursor: pointer;
    max-width: 160px;
    max-height: 31px;
}

.add-user-button:hover {
    background-color: #7d0143;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.page-item {
    list-style: none;
    margin: 0 5px;
}

.page-link {
    text-decoration: none;
    color: #007bff;
    padding: 5px 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
}

.page-item.active .page-link {
    background-color: #FF0087;
    color: white;
    border-color: #007bff;
}

.thAvatarUsuario {
    width: 50px !important;
}

.avatarUsuarioEmpleoyee {
    max-width: 35px;
    max-height: 35px;
    border-radius: 50%;
}