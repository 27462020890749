body {
    background: #000000;
    font-family: 'SF Pro Text', Arial, sans-serif;
}

.eventosContainer {
    width: 100%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.eventosHeader {
    width: 71%;
    height: 57px;
    padding: 8px;
    background: #1c1c1c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
}

.eventosTitle {
    color: white;
    font-size: 20px;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.38px;
    word-wrap: break-word;
}

.searchContainer {
    display: flex;
    align-items: center;
    gap: 16px;
}

.searchBox {
    width: 524px;
    height: 28px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    background: #1c1c1c;
    border: 1px solid #f4f4f4;
    border-radius: 16px;
}

.searchIcon {
    width: 13px;
    height: 13px;
    position: relative;
}

.searchVector {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 1.62px;
    top: 1.62px;
}

.searchVectorCross {
    width: 2.36px;
    height: 2.36px;
    position: absolute;
    border: 2px solid #7e7e7e;
    left: 9.02px;
    top: 9.02px;
}

.searchInput {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: #b3b3b3;
    font-size: 12px;
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 500;
    line-height: 16px;
    margin-left: 8px;
}

.addButton {
    width: 24px;
    height: 24px;
    padding: 8px;
    background: #ff0087;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addIcon {
    width: 24px;
    height: 24px;
    position: relative;
}

.iconRectangle {
    width: 24px;
    height: 24px;
    position: absolute;
}

.iconVerticalLine {
    width: 0;
    height: 14px;
    border: 2px solid white;
    position: absolute;
    left: 12px;
    top: 5px;
}

.iconHorizontalLine {
    width: 14px;
    height: 0;
    border: 2px solid white;
    position: absolute;
    left: 5px;
    top: 12px;
}

.eventosGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 1200px;
}

.eventosCard {
    background-color: #1c1c1c;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.eventosTableView {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.eventosSeparator {
    width: 100%;
    height: 1px;
    background-color: #282828;
    box-shadow: 0px 0.3px 0px rgba(0, 0, 0, 0.09);
}

.eventosCell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eventosVFrame {
    display: flex;
    gap: 15px;
}

.eventosImage {
    width: 80px;
    height: 120px;
    border-radius: 8px;
}

.eventosHFrame {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.eventosTitle {
    color: white;
    font-size: 17px;
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 600;
    line-height: 22px;
}

.eventosDetails {
    color: #F4F4F4;
    font-size: 15px;
    font-family: 'SF Pro Text', sans-serif;
    font-weight: 400;
    line-height: 20px;
}

.eventosIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventosChevron {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventosVector {
    width: 14.18px;
    height: 22.80px;
    opacity: 0.70;
    transform-origin: 0 0;
}

/* Media Queries */
@media (max-width: 1024px) {

    .eventosHeader {
        width: 98%;
    }

    .searchBox {
        width: 100%;
    }

    .eventosGrid {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .eventosHeader {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .searchContainer {
        width: 100%;
        flex-direction: column;
        gap: 8px;
    }
}

@media (max-width: 480px) {
    .eventosTitle {
        font-size: 18px;
    }

    .searchBox {
        padding: 6px 12px;
    }

    .searchInput {
        font-size: 10px;
        margin-left: 4px;
    }

    .addButton {
        width: 20px;
        height: 20px;
        padding: 6px;
    }

    .addIcon {
        width: 20px;
        height: 20px;
    }

    .eventosCard {
        padding: 12px;
    }

    .eventosTitle {
        font-size: 15px;
    }

    .eventosDetails {
        font-size: 13px;
    }

    .eventosImage {
        width: 60px;
        height: 90px;
    }
}