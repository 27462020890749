/* src/pages/Mensajes.css */
.mensajes-inbox-container {
  padding: 20px;
  width: 100%; 
  margin: initial;
  color: #ffffff;
}

.mensajes-inbox-list {
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  max-width: fit-content;
}

.mensajes-inbox-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mensajes-inbox-item:last-child {
  border-bottom: none;
}

.mensajes-inbox-item:hover {
  background-color: #FF0087;
}

.mensajes-inbox-item img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.mensajes-inbox-text h3 {
  margin: 0;
  font-size: 16px;
}

.mensajes-inbox-text p {
  margin: 0;
  color: #ffffff;
  font-size: 14px;
}

/* Estilo adicional para el elemento seleccionado, si se requiere */
.mensajes-selected-inbox-item {
  background-color: #e8f0fe;
}

.mensajes-group-1592-Inbox {
  width: 24px !important;
  height: 24px !important;
  margin-top: -100px !important;
  margin-left: 600px !important;
}

.new-mensajes-group-1592-Inbox {
  width: 70px !important;
  height: 70px !important;
  margin-top: -100px !important;
  margin-bottom: -20px !important;
  margin-left: 581px !important;
}

.mensajes-group-count-Inbox {
  margin-top: -25px !important;
  margin-left: 630px !important;
}